import React, { useState, useEffect } from "react";
import { LinearProgress, Typography, Box } from "@mui/material";
import logos from "../assets/logos.png";

const Loader = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: "transparent",
      }}
    >
      <img
        src={logos}
        alt="Logo"
        style={{ width: 150, height: "auto", marginTop: 16 }}
      />
      <Box sx={{ width: "50%" }}>
        <LinearProgress
          sx={{ height: "20px" }}
          variant="determinate"
          value={progress}
        />
      </Box>
      <Typography
        variant="body2"
        color="text.secondary"
        style={{ marginTop: 8 }}
      >
        {`${Math.round(progress)}%`}
      </Typography>
    </div>
  );
};

export default Loader;
