import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "./Loader";

// Lazy load the components
const Opposition = lazy(() => import("../pages/trademark/Opposition"));
const Renewal = lazy(() => import("../pages/trademark/Renewal"));
const Registration = lazy(() => import("../pages/trademark/Registration"));
const Watch = lazy(() => import("../pages/trademark/Watch"));
const Objection = lazy(() => import("../pages/trademark/Objection"));
const Assignment = lazy(() => import("../pages/trademark/Assignment"));
const Search = lazy(() => import("../pages/trademark/Search"));
const LandingPage = lazy(() => import("../LandingPage"));
const PatentSearch = lazy(() => import("../pages/patent/Search"));
const ProvisionalPatent = lazy(() => import("../pages/patent/Provisional"));
const PatentRegistration = lazy(() => import("../pages/patent/Registration"));
const CopyrightRegistration = lazy(() =>
  import("../pages/copyright/Registration")
);
const DesignRegistration = lazy(() =>
  import("../pages/design/DesignRegistration")
);
const DesignObjectionReply = lazy(() =>
  import("../pages/design/DesignObjectionReply")
);
const DesignDepartmentHearing = lazy(() =>
  import("../pages/design/DesignDepartmentHearing")
);
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const TermsOfService = lazy(() => import("./TermsOfService"));
const NotFound = lazy(() => import("./NotFound"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<div><Loader/></div>}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/opposition" element={<Opposition />} />
        <Route path="/renewal" element={<Renewal />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/watch" element={<Watch />} />
        <Route path="/objection" element={<Objection />} />
        <Route path="/assignment" element={<Assignment />} />
        <Route path="/search" element={<Search />} />

        <Route path="/patentSearch" element={<PatentSearch />} />
        <Route path="/patentProvisional" element={<ProvisionalPatent />} />
        <Route path="/patentRegistration" element={<PatentRegistration />} />

        <Route
          path="/copyrightRegistration"
          element={<CopyrightRegistration />}
        />

        <Route path="/designRegistration" element={<DesignRegistration />} />
        <Route
          path="/designObjectionReply"
          element={<DesignObjectionReply />}
        />
        <Route
          path="/designDepartmentHearing"
          element={<DesignDepartmentHearing />}
        />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
