import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const HelmetComponent = ({ title, description, keywords, image, url }) => {
  return (
    <HelmetProvider>
      <Helmet>
        {/* Basic SEO Tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        {/* Open Graph for Social Media */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        {/* Canonical URL */}
        <link rel="canonical" href={url} />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "EasyTM",
              "url": "https://www.easytm.in",
              "logo": "https://www.easytm.in/logo.png",
              "sameAs": [
                "https://www.linkedin.com/company/easytm-private-limited",
                "https://www.facebook.com/people/Easytm-Consultant-Private-Limited/61565150445439/",
                "https://www.instagram.com/easytm001"
              ],
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9817594030",
                "contactType": "Customer Service"
              }
            }
          `}
        </script>
      </Helmet>
    </HelmetProvider>
  );
};

export default HelmetComponent;
