import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import AppAppBar from "./components/AppAppBar";
import AppRoutes from "./components/AppRoutes";
import ToggleCustomTheme from "./components/ToggleCustomTheme";
import useThemeToggle from "./hooks/useThemeToggle";
import GoHomeButton from "./components/GoHomeButton";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import HelmetComponent from "./components/HelmetComponent";

function App() {
  const { mode, showCustomTheme, theme, toggleColorMode, toggleCustomTheme } =
    useThemeToggle();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <HelmetComponent
  title="EasyTM - Your Trusted Partner"
  description="EasyTM provides trademark registration, company formation, legal advisory, and more."
  keywords="trademark, company registration, legal advisory, business consultancy"
  image="https://www.easytm.in/images/social-share-image.png"  // Ensure this points to a real image URL
  url="https://www.easytm.in"
/>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
          <AppRoutes />
          <ScrollToTopButton />
          <GoHomeButton />
          {/* <ToggleCustomTheme
          showCustomTheme={showCustomTheme}
          toggleCustomTheme={toggleCustomTheme}
        /> */}

          <Footer />
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
