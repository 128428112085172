import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import logos from "../assets/logos.png";

const logoStyle = {
  width: "80px",
  height: "auto",
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      <Link href="https://mui.com/" target="_blank" rel="noopener noreferrer">
        easytm consultant private limited&nbsp;
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const appBarRoutes = {
    trademarkRegistration: "/registration",
    trademarkOpposition: "/opposition",
    trademarkAssignment: "/assignment",
    trademarkObjections: "/objection",
    trademarkRenewal: "/renewal",
    trademarkSearch: "/search",
    trademarkWatch: "/watch",
    patentRegistration: "/patentRegistration",
    patentSearch: "/patentSearch",
    provisionalPatent: "/patentProvisional",
    designRegistration: "/designRegistration",
    designObjectionReply: "/designObjectionReply",
    designDepartmentHearing: "/designDepartmentHearing",
    copyrightRegistration: "/copyrightRegistration",
    privacyPolicy: "/privacy-policy",
    termsOfService: "/terms-of-service",
  };

  return (
    <Container
      id="footer"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "20%" },
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Company Info
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Regd Office: Shop No-09,
            <br /> Idgah Road, New Rajiv Colony,
            <br />
            Safidon City, Jind, Safidon, <br />
            Haryana, India, 126112
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Panipat Office: Shop no 22, ist floor,
            <br /> Subhash Nagar,Teshsil Camp,
            <br />
            Panipat, <br />
            Haryana, India, 132103
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Delhi Office: 5/238, Gali no 4,
            <br />
            Near Laxmi sweets, Laxmi Nagar
            <br /> New Delhi,
            <br /> 110092
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Karnataka Office: No 03, Ground Floor,
            <br />
            Khaan Plaza , Opp Bilal Masjid,
            <br />
            Near Pai Hotel Circle Tiptur , Tumkur
            <br /> karnataka,
            <br /> 572201
          </Typography>
          {/* <Typography variant="body2" color="text.secondary">
            Email: easytmpvtltd@gmail.com
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Contact No: +91 9991640164 , +91 9817594030
          </Typography> */}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Trademarks
          </Typography>
          <Link
            color="text.secondary"
            href={appBarRoutes.trademarkRegistration}
            target="_blank"
            rel="noopener noreferrer"
          >
            Trademark Registration
          </Link>
          <Link
            color="text.secondary"
            href={appBarRoutes.trademarkOpposition}
            target="_blank"
            rel="noopener noreferrer"
          >
            Trademark Opposition
          </Link>
          <Link
            color="text.secondary"
            href={appBarRoutes.trademarkAssignment}
            target="_blank"
            rel="noopener noreferrer"
          >
            Trademark Assignment
          </Link>
          <Link
            color="text.secondary"
            href={appBarRoutes.trademarkObjections}
            target="_blank"
            rel="noopener noreferrer"
          >
            Trademark Objections
          </Link>
          <Link
            color="text.secondary"
            href={appBarRoutes.trademarkRenewal}
            target="_blank"
            rel="noopener noreferrer"
          >
            Trademark Renewal
          </Link>

          <Link
            color="text.secondary"
            href={appBarRoutes.trademarkSearch}
            target="_blank"
            rel="noopener noreferrer"
          >
            Trademark Search
          </Link>
          <Link
            color="text.secondary"
            href={appBarRoutes.trademarkWatch}
            target="_blank"
            rel="noopener noreferrer"
          >
            Trademark Watch
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Patent
          </Typography>
          <Link
            color="text.secondary"
            href={appBarRoutes.patentRegistration}
            target="_blank"
            rel="noopener noreferrer"
          >
            Patent Registration
          </Link>
          <Link
            color="text.secondary"
            href={appBarRoutes.patentSearch}
            target="_blank"
            rel="noopener noreferrer"
          >
            Patent Search/check
          </Link>
          <Link
            color="text.secondary"
            href={appBarRoutes.provisionalPatent}
            target="_blank"
            rel="noopener noreferrer"
          >
            Provisional Patent
          </Link>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Design
          </Typography>
          <Link
            color="text.secondary"
            href={appBarRoutes.designRegistration}
            target="_blank"
            rel="noopener noreferrer"
          >
            Design Registration
          </Link>
          <Link
            color="text.secondary"
            href={appBarRoutes.designObjectionReply}
            target="_blank"
            rel="noopener noreferrer"
          >
            Design Objection Reply
          </Link>
          <Link
            color="text.secondary"
            href={appBarRoutes.designDepartmentHearing}
            target="_blank"
            rel="noopener noreferrer"
          >
            Design Department Hearing
          </Link>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Copyright
          </Typography>
          <Link
            color="text.secondary"
            href={appBarRoutes.copyrightRegistration}
            target="_blank"
            rel="noopener noreferrer"
          >
            Copyright Registration
          </Link>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 4,
              mt: { xs: 2, sm: 16 },
              // mt: 16,
              minWidth: { xs: "100%", sm: "30%" },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="body2" fontWeight={600} gutterBottom>
                Confused ?
              </Typography>
              <Typography variant="body2" color="text.secondary" mb={2}>
                If you are confused, you can send us your contact number.
                <br />
                Our appropriate member will get back to you.
              </Typography>
              <Stack direction="row" spacing={1} useFlexGap>
                <TextField
                  id="outlined-basic"
                  type="number"
                  hiddenLabel
                  size="small"
                  variant="outlined"
                  fullWidth
                  aria-label="Enter Contact no"
                  placeholder="Contact no"
                  inputProps={{
                    autoComplete: "off",
                    "aria-label": "Enter Contact no",
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ flexShrink: 0 }}
                >
                  Get Call
                </Button>
              </Stack>
              <Box sx={{ ml: "-15px" }}>
                <img src={logos} style={logoStyle} alt="logo of sitemark" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Link
            color="text.secondary"
            href={appBarRoutes.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link
            color="text.secondary"
            href={appBarRoutes.termsOfService}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>
          {/* <Copyright /> */}
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
          }}
        >
          <IconButton
            color="inherit"
            href="https://www.facebook.com/people/Easytm-Consultant-Private-Limited/61565150445439/"
            aria-label="Facebook"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.instagram.com/easytm001"
            aria-label="Twitter"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ alignSelf: "center" }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/easytm-private-limited"
            aria-label="LinkedIn"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
