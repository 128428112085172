// src/components/GoHomeButton.js
import React from 'react';
import { Fab } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, useLocation } from 'react-router-dom';

const GoHomeButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    navigate('/');
  };

  // Don't show the button if already on the home page
  if (location.pathname === '/') {
    return null;
  }

  return (
    <Fab
      onClick={handleClick}
      color="secondary"
      size="small"
      aria-label="go home"
      sx={{ position: 'fixed', bottom: 16, left: 16 }}
    >
      <HomeIcon />
    </Fab>
  );
};

export default GoHomeButton;
