
import React from 'react';
import { createTheme } from '@mui/material/styles';
import getLPTheme from '../getLPTheme';

const useThemeToggle = () => {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);

  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return {
    mode,
    showCustomTheme,
    theme: showCustomTheme ? LPtheme : defaultTheme,
    toggleColorMode,
    toggleCustomTheme,
  };
};

export default useThemeToggle;
